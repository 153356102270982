import styled from 'styled-components';

import {
  SYS_SPACE_FIXED_350,
  SYS_SPACE_FIXED_600,
  SYS_PAGE_MARGIN_LG,
  SYS_SPACE_FIXED_500,
  SYS_SPACE_FIXED_1000,
  SYS_BREAKPOINT_MD,
  SYS_BREAKPOINT_LG,
  SYS_BREAKPOINT_XL,
  SYS_BREAKPOINT_2_XL,
} from '@oneaudi/unified-web-common';
import { LayoutItem } from '@oneaudi/unified-web-components';
import type { ContainerDivProps, TextWrapperProps } from '../../@types/interfaces';

export const ContainerDiv = styled.div<ContainerDivProps>`
  background-color: ${(props) => (props.theme === 'light' ? '#FFFFFF' : '#000000')};
  padding: ${(props) =>
    props.bottomMargin
      ? `${SYS_SPACE_FIXED_500} ${SYS_SPACE_FIXED_350}`
      : `${SYS_SPACE_FIXED_500} ${SYS_SPACE_FIXED_350} 0`};

  sup a {
    text-decoration: none;
    color: inherit;
  }

  @media (min-width: ${SYS_BREAKPOINT_MD}) {
    padding: ${(props) =>
      props.bottomMargin
        ? `${SYS_SPACE_FIXED_600} ${SYS_SPACE_FIXED_500}`
        : `${SYS_SPACE_FIXED_600} ${SYS_SPACE_FIXED_500} 0`};
  }

  @media (min-width: ${SYS_BREAKPOINT_LG}) {
    padding: ${(props) =>
      props.bottomMargin
        ? `${SYS_PAGE_MARGIN_LG}`
        : `${SYS_PAGE_MARGIN_LG} ${SYS_PAGE_MARGIN_LG} 0`};
  }

  @media (min-width: ${SYS_BREAKPOINT_XL}) {
    padding: ${(props) =>
      props.bottomMargin ? `${SYS_SPACE_FIXED_1000} 96px` : `${SYS_SPACE_FIXED_1000} 96px 0`};
  }
`;

export const TextLayout = styled.div<ContainerDivProps>`
  background-color: ${(props) => (props.theme === 'light' ? '#FFFFFF' : '#000000')};
  padding: ${(props) =>
    props.bottomMargin ? `${SYS_SPACE_FIXED_500} 0}` : `${SYS_SPACE_FIXED_500} 0 0`};

  ul,
  ol {
    display: inline-block;
    ${({ justification }) =>
      justification === 'center'
        ? `
        margin: 0;
        padding: 0;
      `
        : ''};

    li {
      ::before {
        left: unset;
        position: relative !important;
      }
      sup a:-webkit-any-link {
        color: unset !important;
      }
    }
  }

  @media (min-width: ${SYS_BREAKPOINT_XL}) {
    max-width: ${(props) => (props.reduceTextWidth ? '749px' : 'auto')};
    margin: 0 auto;
  }

  @media (min-width: ${SYS_BREAKPOINT_2_XL}px) {
    max-width: ${(props) => (props.reduceTextWidth ? '849px' : 'auto')};
    margin: 0 auto;
  }
`;

export const StyledTextLayoutItem = styled(LayoutItem)`
  width: 100%;
`;

export const ButtonContainerDiv = styled.div<TextWrapperProps>`
  margin-block-start: ${SYS_SPACE_FIXED_500};
  margin-bottom: 0;
  text-align: ${({ align }) => align};
`;

export const TextWrapperDiv = styled.div<TextWrapperProps>`
  text-align: ${(props) => props.align};

  pre {
    white-space: pre-wrap;
  }
`;
