export const allowedTagsAndAttributes = {
  allowedTags: [
    'b',
    'u',
    'strong',
    'a',
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'ul',
    'ol',
    'li',
    'pre',
    'blockquote',
    'hr',
    'span',
    'div',
    'br',
  ],
  allowedAttributes: {
    a: ['href', 'name', 'target', 'rel'],
    h3: ['data-test-id'],
    span: ['style'],
    blockquote: ['cite'],
  },
  allowedStyles: {
    span: {
      'text-decoration': [/^underline$/],
    },
  },
};
